import React, { memo, forwardRef } from 'react'
import styled from 'styled-components'

import SnackbarContentBase from '@material-ui/core/SnackbarContent'

const StyledSnackbarContent = styled(SnackbarContentBase)`
  font-size: 1.4rem !important;
`

// eslint-disable-next-line react/display-name
const SnackbarContent = forwardRef((props, ref) => {
  return <StyledSnackbarContent ref={ref} {...props} />
})

export default memo(SnackbarContent)

import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Image as ImageBase } from 'react-datocms'

import Flex from 'components/atoms/Flex'
import theme from 'constants/theme'

const Container = styled(Flex)`
  width: 75px;
  height: 75px;
  border: 1px solid ${theme.colors.primary};
  padding: 5px;
  overflow: hidden;
  background: white;

  &.micro {
    width: 40px;
    height: 40px;
  }
`

const Image = styled(ImageBase)`
  display: block;
`

const MiniProductImage = ({ imageData, micro }) => {
  const containerClass = micro ? 'micro' : ''

  return (
    <Container center className={containerClass}>
      <Image data={imageData} />
    </Container>
  )
}

MiniProductImage.propTypes = {
  imageData: PropTypes.object.isRequired,
}

export default memo(MiniProductImage)

import React from 'react'

require('typeface-open-sans')
import { SnackbarProvider } from 'notistack'
import SnackbarContent from 'components/atoms/utils/SnackbarContent'

import { GlobalContextProvider } from 'context/GlobalContext'
import { CollectionContextProvider } from 'context/CollectionContext'
import { SlidesOverlayContextProvider } from 'context/SlidesOverlayContext'
import { SwatchDrawerContextProvider } from 'context/SwatchDrawerContext'

// React Context in Browser
export const wrapRootElement = ({ element }) => {
  return (
    <>
      <GlobalContextProvider>
        <SnackbarProvider
          maxSnack={5}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          content={(key, message) => (
            <SnackbarContent id={key} message={message} />
          )}
        >
          <CollectionContextProvider>
            <SlidesOverlayContextProvider>
              <SwatchDrawerContextProvider>
                {element}
              </SwatchDrawerContextProvider>
            </SlidesOverlayContextProvider>
          </CollectionContextProvider>
        </SnackbarProvider>
      </GlobalContextProvider>
    </>
  )
}

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }

  if (!('scrollBehavior' in document.documentElement.style)) {
    const ss = await import('smoothscroll-polyfill')
    ss.polyfill()
  }
}

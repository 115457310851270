import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose, layout, position } from 'styled-system'
import is from 'styled-is'
import { Flex as Base } from 'rebass'

const FlexBase = styled(Base)`
  ${is('full')`
    width: 100%;
    height: 100%;
    flex-basis: 100%;
  `};

  ${is('fullWidth')`
    width: 100%;
  `};
  ${is('fullHeight')`
    height: 100%;
  `};

  ${is('center')`
    align-items: center;
    justify-content: center;
  `};

  ${is('verticalCenter')`
    align-items: center;
  `};
`

const StyledFlex = styled(FlexBase)(compose(layout, position))

const Flex = (props) => <StyledFlex {...props} />

Flex.propTypes = {
  center: PropTypes.bool,
  full: PropTypes.bool,
}

export default memo(Flex)

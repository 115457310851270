import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react'
import { useGlobalActionsContext } from 'context/GlobalContext'
import { useCollectionActionsContext } from 'context/CollectionContext'

const SlidesOverlayContext = createContext()
const SlidesOverlayActionsContext = createContext()

export const useSlidesOverlayContext = () => useContext(SlidesOverlayContext)
export const useSlidesOverlayActionsContext = () =>
  useContext(SlidesOverlayActionsContext)

const SlidesOverlayContextProvider = ({ children }) => {
  const { addToCollection } = useCollectionActionsContext()
  const { updateSolidNav } = useGlobalActionsContext()
  const [open, setOpen] = useState(false)
  const [slides, setSlides] = useState(undefined)
  const [slidesType, setSlidesType] = useState(undefined)
  const [brochureUrl, setBrochureUrl] = useState(undefined)
  const [startIndex, setStartIndex] = useState(1)

  const handleSetSlides = (slides) => {
    setSlides(slides)
  }
  const handleSetSlidesType = (type) => {
    setSlidesType(type)
  }
  const handleSetBrochureUrl = (url) => {
    setBrochureUrl(url)
  }

  const handleAddToCollection = (collectionItems, index) => {
    setStartIndex(index)
    addToCollection(collectionItems)
  }
  const openSlidesOverlay = (index) => {
    setStartIndex(index)
    setOpen(true)
  }

  const closeSlidesOverlay = () => {
    setOpen(false)
    updateSolidNav(true)
  }

  const actions = useMemo(
    () => ({
      openSlidesOverlay,
      closeSlidesOverlay,
      handleSetSlides,
      handleSetSlidesType,
      handleSetBrochureUrl,
      handleAddToCollection,
    }),
    [handleAddToCollection]
  )

  return (
    <SlidesOverlayContext.Provider
      value={{
        open,
        startIndex,
        brochureUrl,
        slides,
        slidesType,
      }}
    >
      <SlidesOverlayActionsContext.Provider value={actions}>
        {children}
      </SlidesOverlayActionsContext.Provider>
    </SlidesOverlayContext.Provider>
  )
}

export { SlidesOverlayContext, SlidesOverlayContextProvider }

import React, { memo } from 'react'

import MiniProductImage from 'components/atoms/media/MiniProductImage'
import Flex from 'components/atoms/Flex'
import Box from 'components/atoms/Box'

const SnackbarProductContent = ({
  imageData,
  productName,
  quantity,
  action,
}) => {
  let message
  switch (action) {
    case 'quantity':
      message = `quantity was updated to ${quantity}.`
      break
    case 'added':
      message = 'was added to your collection.'
      break
    case 'removed':
      message = 'was removed from your collection.'
      break
  }
  return (
    <Flex verticalCenter>
      <MiniProductImage imageData={imageData} micro />
      <Box ml={3}>
        {productName} {message}
      </Box>
    </Flex>
  )
}

export default memo(SnackbarProductContent)

import { GraphQLClient } from 'graphql-request'

const apiToken = process.env.GATSBY_DATOCMS_API_KEY
const endpoint = 'https://graphql.datocms.com'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + apiToken,
}
const datoCMSClient = new GraphQLClient(endpoint, { headers })
export default datoCMSClient

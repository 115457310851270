import breakpointsBase from '../constants/breakpoints'

const fonts = {
  sans: "'Open Sans', sans-serif",
  serif: "'orpheuspro', serif;",
}

const fontSizes = [
  '.5rem',
  '1rem',
  '2rem',
  '3rem',
  '4rem',
  '5rem',
  '6rem',
  '7rem',
  '8rem',
  '9rem',
  '10rem',
  '11rem',
  '12rem',
]

const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
}

const theme = {
  space: [
    0,
    10,
    20,
    30,
    40,
    50,
    60,
    70,
    80,
    90,
    100,
    110,
    120,
    130,
    140,
    150,
    160,
    170,
    180,
    190,
    200,
  ],
  spaceUnit: 'px',
  breakpoints: [
    breakpointsBase.sm,
    breakpointsBase.md,
    breakpointsBase.lg,
    breakpointsBase.xl,
  ],
  breakpointsPx: {
    xs: `${breakpointsBase.xs}px`,
    sm: `${breakpointsBase.sm}px`,
    md: `${breakpointsBase.md}px`,
    lg: `${breakpointsBase.lg}px`,
    xl: `${breakpointsBase.xl}px`,
  },
  horizontalContainerPadding: {
    xs: 2,
    sm: 3,
    md: 4,
  },
  colors: {
    primary: '#b79b6A',
    secondary: '#1d2434',
    textDefault: '#1a1a1a',
    outlineLight: 'rgba(255,255,255,.4)',
    outlineDark: '#dfdfdf',
    outlineExtraLight: 'rgba(255,255,255,.14)',
    greyLight: '#f2f2f2',
    darkBg: '#1e2225',
  },
  fonts: fonts,
  fontSizes: fontSizes,
  fontWeights: fontWeights,
  textStyles: {
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.2rem',
    },
    smallSansHeading: {
      fontFamily: fonts.sans,
      textTransform: 'uppercase',
      letterSpacing: '0.1rem',
      fontWeight: fontWeights.bold,
    },
  },
  borderRadius: {
    small: '4px',
    medium: '6px',
    large: '8px',
  },
  shadows: {
    light: '0 2px 2px rgba(0, 0, 0, 0.1)',
    strong: '0 6px 8px rgba(0, 0, 0, 0.1)',
  },
  masonryCols: {
    default: 4,
    1600: 3,
    1192: 2,
    868: 1,
  },
  masonryGutter: '16px',
  transitions: {
    default: '.2s ease-in-out',
    medium: '.5s ease',
    long: '1s ease',
  },
  sectionPadding: {
    xs: 4,
    sm: 5,
    md: 6,
    lg: 8,
    xl: 17,
  },
  twoColLayoutExtraHeight: '60px',
  contentComponentSpacing: {
    small: {
      xs: 4,
      lg: 6,
      xl: 7,
    },
    medium: {
      xs: 3,
      sm: 3,
      md: 3,
      lg: 4,
      xl: 5,
    },
  },
}

theme.breakpoints.xs = theme.breakpointsPx.xs
theme.breakpoints.sm = theme.breakpointsPx.sm
theme.breakpoints.md = theme.breakpointsPx.md
theme.breakpoints.lg = theme.breakpointsPx.lg
theme.breakpoints.xl = theme.breakpointsPx.xl

export default theme

import React, { createContext, useContext, useState, useMemo } from 'react'

const SwatchDrawerContext = createContext()
const SwatchDrawerActionsContext = createContext()

export const useSwatchDrawerContext = () => useContext(SwatchDrawerContext)
export const useSwatchDrawerActionsContext = () =>
  useContext(SwatchDrawerActionsContext)

const SwatchDrawerContextProvider = ({ children }) => {
  const [swatches, setSwatches] = useState(undefined)
  const [open, setOpen] = useState(false)

  const openSwatchDrawer = swatchObj => {
    setSwatches(swatchObj)
    setOpen(true)
  }
  const closeSwatchDrawer = () => {
    setOpen(false)
    setSwatches(undefined)
  }

  const actions = useMemo(
    () => ({
      openSwatchDrawer,
      closeSwatchDrawer,
    }),
    []
  )

  return (
    <SwatchDrawerContext.Provider
      value={{
        swatches,
        open,
      }}
    >
      <SwatchDrawerActionsContext.Provider value={actions}>
        {children}
      </SwatchDrawerActionsContext.Provider>
    </SwatchDrawerContext.Provider>
  )
}

export { SwatchDrawerContext, SwatchDrawerContextProvider }

import React, { memo } from 'react'
import styled from 'styled-components'
import { Box as Base } from 'rebass'
import { compose, layout, position } from 'styled-system'

const Box = styled(Base)(compose(layout, position))

const Container = (props) => <Box {...props} />

export default memo(Container)

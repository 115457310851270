import React, { createContext, useContext, useState, useMemo } from 'react'

const GlobalContext = createContext()
const GlobalActionsContext = createContext()
const GlobalResetContext = createContext()

export const useGlobalContext = () => useContext(GlobalContext)
export const useGlobalActionsContext = () => useContext(GlobalActionsContext)
export const useGlobalResetContext = () => useContext(GlobalResetContext)

const GlobalContextProvider = ({ children }) => {
  const [solidNav, setSolidNav] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useState(undefined)

  const updateSolidNav = (open) => {
    setSolidNav(open)
  }

  const resetStatusMessage = () => setStatusMessage(undefined)

  const actions = useMemo(
    () => ({
      updateSolidNav,
      setIsLoading,
      setStatusMessage,
    }),
    []
  )

  return (
    <GlobalContext.Provider
      value={{
        solidNav,
        isLoading,
        statusMessage,
      }}
    >
      <GlobalActionsContext.Provider value={actions}>
        <GlobalResetContext.Provider
          value={{
            resetStatusMessage: resetStatusMessage,
          }}
        >
          {children}
        </GlobalResetContext.Provider>
      </GlobalActionsContext.Provider>
    </GlobalContext.Provider>
  )
}

export { GlobalContext, GlobalContextProvider }

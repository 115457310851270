import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react'
import { useSnackbar } from 'notistack'
import { useGlobalActionsContext } from 'context/GlobalContext'
import datoCMSClient from 'helpers/datoCMSClient'
import SnackbarProductContent from 'components/atoms/utils/SnackbarProductContent'

const CollectionContext = createContext()
const CollectionActionsContext = createContext()

export const useCollectionContext = () => useContext(CollectionContext)
export const useCollectionActionsContext = () =>
  useContext(CollectionActionsContext)

const isBrowser = typeof window !== 'undefined'

const CollectionContextProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [collection, setCollection] = useState(
    isBrowser
      ? JSON.parse(window.localStorage.getItem('BeachwoodCustomCollection')) ||
          []
      : []
  )

  const addToCollection = (itemIds) => {
    let newCollection = [...collection]

    itemIds.map((id, index) => {
      let action
      let quantity = 1
      const exists =
        newCollection.filter((item) => {
          return item.productId === id.productId && item.itemId === id.itemId
        }).length > 0

      if (exists) {
        newCollection = newCollection.map((item) => {
          quantity = item.quantity + 1
          return item.productId === id.productId && item.itemId === id.itemId
            ? { ...item, quantity: quantity }
            : item
        })
        action = 'quantity'
      } else {
        newCollection.push({ ...id, quantity: quantity })
        action = 'added'
      }

      getProducts(id.productId).then((result) => {
        const content = (
          <SnackbarProductContent
            imageData={result[0].productImage.responsiveImage}
            productName={result[0].items[index].productName}
            quantity={quantity}
            action={action}
          />
        )
        enqueueSnackbar(content)
      })
    })
    setCollection(newCollection)
  }

  const updateQuantity = (collectionItem, newQuantity, snackbar = true) => {
    if (newQuantity > 0) {
      let newCollection = []

      collection.map((item) => {
        item.productId === collectionItem.productId
          ? newCollection.push({ ...item, quantity: newQuantity })
          : newCollection.push(item)
      })

      if (snackbar) {
        getProducts(collectionItem.productId).then((result) => {
          result[0].items.map((item) => {
            const content = (
              <SnackbarProductContent
                imageData={result[0].productImage.responsiveImage}
                productName={item.productName}
                quantity={newQuantity}
                action="quantity"
              />
            )
            enqueueSnackbar(content)
          })
        })
      }
      setCollection(newCollection)
    }
  }

  const removeFromCollection = (collectionItem) => {
    let newCollection = []

    collection.map((item) => {
      if (item.productId !== collectionItem.productId) {
        newCollection.push(item)
      }
    })

    getProducts(collectionItem.productId).then((result) => {
      result[0].items.map((item) => {
        const content = (
          <SnackbarProductContent
            imageData={result[0].productImage.responsiveImage}
            productName={item.productName}
            action="removed"
          />
        )
        enqueueSnackbar(content)
      })
    })
    setCollection(newCollection)
  }

  const getProducts = async (productIds) => {
    try {
      const variables = {
        productIds: productIds,
      }
      const result = await datoCMSClient.request(collectionQuery, variables)

      return result.allProducts
    } catch (error) {
      console.error(JSON.stringify(error, undefined, 2))
    }
  }

  useEffect(() => {
    if (isBrowser) {
      window.localStorage.setItem(
        'BeachwoodCustomCollection',
        JSON.stringify(collection)
      )
    }
  }, [collection])

  const actions = useMemo(
    () => ({
      addToCollection,
      updateQuantity,
      removeFromCollection,
      getProducts,
    }),
    [collection]
  )

  return (
    <CollectionContext.Provider
      value={{
        collection,
      }}
    >
      <CollectionActionsContext.Provider value={actions}>
        {children}
      </CollectionActionsContext.Provider>
    </CollectionContext.Provider>
  )
}

export { CollectionContext, CollectionContextProvider }

const collectionQuery = `
query CollectionQuery  ($productIds: [ItemId!]){
  allProducts(filter: {id: {in: $productIds}}) {
    id
    items {
      sku
      price
      productName
      id
    }
    productImage {
        responsiveImage(
          imgixParams: { w: 75, h: 75, auto: format }
        ) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          bgColor
          base64
        }
    }
    collection {
      collectionName
    }    
  }
}

`
